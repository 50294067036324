import docReady from '../../../assets/js/docReady.js';
import { logAnalyticsEvent } from '../../../assets/js/helpers/bananas.js';

class BestProviderScore {
    constructor(element) {
        this.element = element;
        this.radioInputs = [];
        this.selectInput = null;
        this.cards = {};
    }

    bootstrap() {
        this.element.querySelectorAll('.best-energy-provider-input')
            .forEach((inputElement) => {
                const matchingCategory = this.element.querySelector(`[data-category="${inputElement.value}"]`);
                if (matchingCategory) {
                    this.cards[inputElement.value] = matchingCategory;
                    this.radioInputs.push(inputElement);
                }
            });
        this.selectInput = this.element.querySelector('select');
    }

    toggleCards(slug) {
        Object.keys(this.cards)
            .forEach((key) => {
                if (key !== slug) {
                    this.cards[key].style.display = 'none';
                } else {
                    this.cards[key].style.display = null;
                }
            });
    }

    registerEventListeners() {
        this.radioInputs.forEach((input) => {
            input.addEventListener('change', (event) => {
                this.selectInput.value = event.target.value;
                this.toggleCards(event.target.value);
                logAnalyticsEvent('best-energy-provider-widget.change-category', event.target.value);
            });
        });

        this.selectInput.addEventListener('change', (event) => {
            this.toggleCards(event.target.value);
            logAnalyticsEvent('best-energy-provider-widget.change-category', event.target.value);
            this.radioInputs.forEach((input) => {
                input.checked = input.value === event.target.value;
            });
        });
    }

    init() {
        this.bootstrap();
        this.registerEventListeners();
    }
}

docReady(() => {
    document.querySelectorAll('.best-energy-provider-display')
        .forEach((element) => {
            const bestProviderScore = new BestProviderScore(element);
            bestProviderScore.init();
        });
});
